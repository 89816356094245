<template>
  <b-card-code title="GAME TOP 10">
    <div class="demo-inline-spacing">
      <addcon @loaddata="loaddata"></addcon>
      <editcon @loaddata="loaddata" ref="modal"></editcon>
      <b-button @click="saveindex()" variant="primary"> บันทึก </b-button>
    </div>
    <br />
    <!-- <b-card-text>
      <span>GAME TOP 10</span>
      <code>group</code>
      <span> to </span>
      <code>group</code>
      <span> prop.</span>
    </b-card-text> -->
    <b-row>
      <!-- people group 1 -->
      <b-col md="12">
        <!-- <h6 class="text-primary font-weight-bold mb-2">เลือก</h6> -->

        <!-- draggable -->
        <draggable
          :list="list1"
          tag="ul"
          group="people"
          class="list-group list-group-flush cursor-move"
        >
          <!-- style="border: 1px solid #55b9f3" -->
          <b-list-group-item
            v-for="(listItem, index) in list1"
            :key="index"
            tag="li"
          >
            <div class="d-flex">
              <code
                style="margin-right: 10px; padding-top: 10px; font-size: 16px"
              >
                <b>TOP {{ index + 1 }}</b></code
              >
              <b-avatar size="44" :src="listItem.content_Image" />
              <div>
                <b-card-text class="mb-0 font-weight-bold">
                  {{ listItem.content_title }}
                </b-card-text>

                <small>{{ listItem.content_detail }}</small>
              </div>
            </div>
            <br />
            <span @click="edit_data(listItem.id)" style="margin-right: 20px"
              ><code style="color: yellowgreen"> แก้ไข </code></span
            >
            <span @click="delete_data(listItem.id)"
              ><code style="color: orange"> ลบ </code></span
            >
          </b-list-group-item>
        </draggable>
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BListGroupItem,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BButton,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import api from "@/api";
import addcon from "./AddCon.vue";
import editcon from "./EditCon.vue";

export default {
  components: {
    BCardCode,
    BAvatar,
    BListGroupItem,
    BCardText,
    BRow,
    BCol,
    Prism,
    draggable,
    addcon,
    editcon,
    BButton,
  },
  data() {
    return {
      list1: [],
      list2: [],

      items: [],
      totalRows: 0,
    };
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {
      const params = {
        campaign_type: "date",
      };
      api
        .post("/gamelist", params)
        .then((response) => {
          this.items = response.data[0].contents;
          this.totalRows = response.data[0].contents_total;
          console.log(response.data[0]);
          for (const item of this.items) {
            // console.log(item);
            this.list1.push(item);
            this.list2.push(item);
          }
        })
        .catch((error) => {});
    },
    edit_data(id) {
      this.$refs.modal.showModaledit(id);
    },
    delete_data(id) {
      console.log("delete", id);
      const params = {
        id: id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.post("/contentdel", params).then((response) => {
            console.log(response.data);
            window.location.reload();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Your file has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    saveindex() {
      const params = {
        list: this.list1,
      };
      api
        .post("/update_index_game", params)
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.$swal({
            title: "Good job!",
            text: "อัพเดตข้อมูลเรียบร้อย!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
        title: 'Error!',
        text: ' ลองใหม่อีกครั้ง!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        });
    },
  },
};
</script>
